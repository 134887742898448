import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { ClipLoader } from 'react-spinners';
import Modal from 'react-modal';
import './subscription.css';
import beforeSubscribeAnimation from '../../assets/beforeSubscribeAnimation.json';
import sale from '../../assets/Transport.png';

Modal.setAppElement('#root'); // Set the root element for accessibility

const SubscriptionForm = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [name, setName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    
    if (value.length === 0) {
      setPhoneError('');
    } else if (value.startsWith('0')) {
      if (!/^0\d{10}$/.test(value)) {
        setPhoneError('Please enter a 10-digit mobile number');
      } else {
        setPhoneError('');
      }
    } else if (!/^\d{10}$/.test(value)) {
      setPhoneError('Please enter a 10-digit mobile number');
    } else {
      setPhoneError('');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneError) {
      return; // Prevent submission if there's a phone error
    }
    setSubmitDisabled(true);
    setLoading(true);

    try {
      const response = await fetch('https://gnadafaluminium.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          phone_number: phoneNumber,
          product_category: productCategory
        })
      });

      if (response.ok) {
        setModalIsOpen(true);
        setName('');
        setPhoneNumber('');
        setProductCategory('');
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      alert('Error!', error.message);
    } finally {
      setSubmitDisabled(false);
      setLoading(false);
    }
  };

  const beforeSubscribeOptions = {
    loop: true,
    autoplay: true,
    animationData: beforeSubscribeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="subscription-container">
      <div className="image-container">
        <img src={sale} alt="Sale" className="sale-image" />
      </div>
      <div className="form-container">
        <div className="form-header">
          <Lottie options={beforeSubscribeOptions} height={150} width={150} />
          <span>Let us contact you!</span>
        </div>
        <form className="subscription-form" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="input-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div>
            <input
              type="tel"
              name="phone_number"
              placeholder="Your Contact *"
              required
              className="input-field"
              value={phoneNumber}
              onChange={handlePhoneChange}
              pattern="^[1-9]\d{9}$"
              maxLength="10"
            />
              {phoneError && <div className="error-message">{phoneError}</div>}
            </div>
            <select
              name="product_category"
              className="input-field"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              required
            >
              <option value="">Select Product Category *</option>
              <option value="Windows">Windows</option>
              <option value="Doors">Doors</option>
              <option value="Other">Other</option>
            </select>
            <button
              type="submit"
              className="submit-button"
              disabled={submitDisabled || phoneNumber.trim() === '' || productCategory === '' || phoneError !== ''}
            >
              {loading ? <ClipLoader size={20} color="#ffffff" /> : 'Subscribe'}
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Subscription Confirmation"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Thank You!</h2>
        <p>We have received your subscription request. We will contact you soon.</p>
        <button onClick={() => setModalIsOpen(false)} className="close-button">Close</button>
      </Modal>
    </div>
  );
};

export default SubscriptionForm;