import React, { useState } from 'react';
import css from './footer.module.css';
import Logo from '../../assets/logo_white.png';
import TermsModal from '../TermsModal/TermsModal';
import {  BiPhone, BiMailSend, BiMapPin, BiTrendingUp, BiAccessibility, BiAdjust, BiPackage, BiDetail } from 'react-icons/bi';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={css.cFooterWrapper}>
      <hr />
      <div className={css.cFooter}>
        <div className={css.logomain}>
          <div className={css.logo}>
            <img src={Logo} alt="Logo" />
          </div>
          <div className={css.logoname}>
            <span>G. NADAF ALUMINIUM</span>
            <span>Doors and Windows</span>
          </div>
        </div>

        <div className={css.info}>
          <div className={css.block}>
            <div className={css.detail}>
              <span>Contact Us</span>
              <span className={css.pngLine}>
                <BiMapPin className={css.icon} />
                <span>Timber Area, Miraj-416410</span>
              </span>
              <span className={css.pngLine}>
                <BiPhone className={css.icon} />
                <a href="tel:9921270549">+91-9921270549</a>
              </span>
              <span className={css.pngLine}>
                <BiMailSend className={css.icon} />
                <a href="mailto:g.nadafaluminium@gmail.com">g.nadafaluminium@gmail.com</a>
              </span>
            </div>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Home</span>
              <span className={css.pngLine}>
                <BiTrendingUp className={css.icon} />
                <a href="/trend">Trending</a>
              </span>
              <span className={css.pngLine}>
                <BiAccessibility className={css.icon} />
                <a href="https://wa.me/9921270549?text=Hello%21%20I%27m%20interested%20in%20exploring%20your%20accessories%20offerings.%20Could%20you%20please%20provide%20more%20details%3F%20Thank%20you%21">Accessories</a>
              </span>
              <span className={css.pngLine}>
                <BiAdjust className={css.icon} />
                <a href="https://wa.me/9921270549?text=Hello%21%20I%27m%20interested%20in%20your%20maintenance%20services%20for%20doors%20and%20windows.%20Could%20you%20please%20provide%20more%20details%3F%20Thank%20you%21">Repairs & Modifications</a>
              </span>
            </div>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Company</span>
              <span className={css.pngLine}>
                <BiDetail className={css.icon} />
                <a href="/about">About Us</a>
              </span>
              <span className={css.pngLine}>
                <BiPackage className={css.icon} />
                <span onClick={() => setShowModal(true)}>Terms & Conditions</span>
              </span>

            </div>
          </div>
        </div>
      </div>

      <a href="https://prophetbird.in" target="_blank" rel="noopener noreferrer" className={css.copyRight}>
        <div>
          <span>Copyright ©2024 by ProphetBird <br /></span>
          <span>All rights reserved.</span>
        </div>
      </a>
      <TermsModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default Footer;
