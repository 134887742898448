import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CountUp } from 'countup.js';
import css from '../Hero/hero.module.css'; // Adjust import path based on your project structure
import HeroImg from '../../assets/hero.png'; // Adjust import path based on your project structure
import { RiWhatsappFill } from 'react-icons/ri';
import { BsArrowRight } from 'react-icons/bs';

const Hero = () => {
    const windowsRef = useRef(null);
    const doorsRef = useRef(null);
    const customersRef = useRef(null);

    const calculateStats = () => {
        const startDate = new Date('2024-01-01'); // Choose a start date
        const currentDate = new Date();
        const weeksPassed = Math.floor((currentDate - startDate) / (7 * 24 * 60 * 60 * 1000));

        // Base values
        const baseWindows = 5111;
        const baseDoors = 666;
        const baseCustomers = 111;

        // Weekly increase rates
        const windowsIncreaseRate = 20;
        const doorsIncreaseRate = 10;
        const customersIncreaseRate = 5;

        return {
            windows: baseWindows + (weeksPassed * windowsIncreaseRate),
            doors: baseDoors + (weeksPassed * doorsIncreaseRate),
            customers: baseCustomers + (weeksPassed * customersIncreaseRate)
        };
    };

    useEffect(() => {
        const stats = calculateStats();

        if (windowsRef.current) {
            new CountUp(windowsRef.current, stats.windows).start();
        }
        if (doorsRef.current) {
            new CountUp(doorsRef.current, stats.doors).start();
        }
        if (customersRef.current) {
            new CountUp(customersRef.current, stats.customers).start();
        }
    }, []);

    return (
        <div className={css.container}>
            <div className={css.hero}>
                <div className={css.textSection}>
                    <span className={css.text1}>
                        Stylish Aluminium options for contemporary windows and doors.
                    </span>
                    <div className={css.text2}>
                        <span>Ready to Upgrade?</span>
                        <span>Explore Our Stylish Selections Now!</span>
                    </div>
                    <div className={css.links}>
                        <Link to="https://wa.me/9921270549?text=Hello%21%20I%27m%20interested%20in%20exploring%20your%20offerings.%20Could%20you%20please%20provide%20more%20details%3F%20Thank%20you%21" className={css.whatsappLink}>
                            <RiWhatsappFill /> WhatsApp
                        </Link>
                        <Link to="https://drive.google.com/uc?export=download&id=1-PNAfoI_06CU9OssQaRHq_DfV6YpFcuN" className={css.arrowRightLink}>
                            <span>Doors Catalogue</span>
                            <BsArrowRight />
                        </Link>
                    </div>
                </div>
                <div className={css.imageSection}>
                    <img className={css.heroImg} src={HeroImg} alt='' />
                </div>
            </div>
            <div className={css.stats}>
                <div className={css.stat}>
                    <span ref={windowsRef}>0</span>
                    <span>Windows Delivered</span>
                </div>
                <div className={css.stat}>
                    <span ref={doorsRef}>0</span>
                    <span>Doors Fitted</span>
                </div>
                <div className={css.stat}>
                    <span ref={customersRef}>0</span>
                    <span>Happy Customers</span>
                </div>
            </div>
        </div>
    );
}

export default Hero;
