import React from 'react';
import css from './termsModal.module.css';

const TermsModal = ({ showModal, setShowModal }) => {
  if (!showModal) return null;

  return (
    <div className={css.modalOverlay} onClick={() => setShowModal(false)}>
      <div className={css.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2>Terms & Conditions</h2>
        <div className={css.modalBody}>
          <h3>1. Introduction</h3>
          <p>Welcome to G. NADAF ALUMINIUM. By using our services, you agree to the following terms and conditions. Please read them carefully.</p>
          
          <h3>2. Services</h3>
          <p>We offer the following services:
            <ul>
              <li><strong>Door Fitting:</strong> Expert fitting services to enhance functionality and aesthetics.</li>
              <li><strong>Window Fitting:</strong> Professional installation for energy efficiency and style.</li>
              <li><strong>Carpentry:</strong> Custom furniture and intricate woodwork.</li>
              <li><strong>Glass Fitting:</strong> Seamless glass installations for safety and elegance.</li>
              <li><strong>Powder Coating:</strong> Durable coating services for metal surfaces.</li>
            </ul>
          </p>
          <p>We also manufacture laminated and non-laminated doors and windows, as well as aluminum partitions and glazing.</p>
          
          <h3>3. Service Availability</h3>
          <p>Our services are available within Maharashtra, Goa and Karnataka states. We may modify or discontinue services without prior notice.</p>
          
          <h3>4. Quotes and Payment</h3>
          <p>Project pricing is determined by the specified requirements and is subject to adjustment if the work scope changes. Payment terms: 50% deposit required upfront, with the remaining 50% due within 5 business days of project completion. A 5% surcharge may apply to overdue payments.
          </p>
          
          <h3>5. Responsibilities</h3>
          <p><strong>Customer Responsibilities:</strong> Provide accurate information and ensure access to the site. We are not responsible for delays or additional costs due to incomplete or inaccurate information.</p>
          <p><strong>Our Responsibilities:</strong> Perform services with reasonable care and skill.</p>
          
          <h3>6. Liability</h3>
          <p>We are not liable for any indirect or consequential losses or damages arising from our services. Our liability is limited to the cost of the service provided.</p>
          
          <h3>7. Materials</h3>
          <p>All materials provided are subject to use conditions. We do not offer warranties or guarantees on materials.</p>
          
          <h3>8. Cancellation and Rescheduling</h3>
          <p><strong>Cancellation Policy:</strong> For orders with a 50% deposit paid, provide at least 48 hours' notice to cancel without penalty. Cancellations made within 48 hours may incur a 10% fee. No charges apply if no deposit has been made.</p>
          <p><strong>Rescheduling:</strong> We will make reasonable efforts to accommodate rescheduling requests, subject to availability.</p>
          
          <h3>9. Privacy</h3>
          <p><strong>Privacy Policy:</strong> We handle your personal information in accordance with the following privacy policy:</p>

          <p><strong>Information Collection:</strong> We collect personal data you provide, including name, contact details, and payment information.</p>

          <p><strong>Use of Information:</strong> Your data is used to process orders, improve our services, and communicate with you about your purchases.</p>

          <p><strong>Data Protection:</strong> We implement security measures to protect your information from unauthorized access or disclosure.</p>

          <p><strong>Third-Party Sharing:</strong> We do not sell your personal data. We may share information with service providers who assist in order fulfillment.</p>

          <p><strong>Your Rights:</strong> You have the right to access, correct, or delete your personal information. Contact us for any privacy-related requests.</p>

          <p><strong>Updates:</strong> This privacy policy may be updated periodically. Check back for any changes.</p>

          <p>By using our services, you agree to the terms of this privacy policy.</p>

          <h3>10. Changes to Terms</h3>
          <p>We may update these terms and conditions from time to time. Changes will be posted on our website, and continued use of our services constitutes acceptance of the updated terms.</p>
          
          <h3>11. Governing Law</h3>
          <p>These terms and conditions are governed by the State laws and Indian Constitution.</p>
        </div>
        <button className={css.closeButton} onClick={() => setShowModal(false)}>Close</button>
      </div>
    </div>
  );
};

export default TermsModal;
