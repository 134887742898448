import React from 'react';
import Footer from '../../src/Components/Footer/Footer';
import Header from '../../src/Components/Headers/header';
import ServSlider from '../../src/Components/Services/service.slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Redirect = () => {
    return (
        <div className="error-page">
            <Header />
            <div className="service-content">
                <div className="s-title">
                    <span>404 Error! 🫢<br /> Page Not Found</span>
                </div>
                <ServSlider />
            </div>
            <Footer />
        </div>
    );
};

export default Redirect;
