import img1 from '../assets/img1.jpg'
import img2 from '../assets/img2.jpg'
import img3 from '../assets/img3.jpg'
import img4 from '../assets/img4.jpg'
import img5 from '../assets/img5.jpg'
import img6 from '../assets/img6.jpg'
import img7 from '../assets/img7.jpeg'

export const SliderProducts = [
  {
    name: 'Aluminium Windows',
    detail: 'Durable, stylish windows.\nEnhance your living spaces.\nLong-lasting quality.',
    img: img1,
    link: 'https://photos.app.goo.gl/HLRZui6ZnJTJMsiE7'
  },
  {
    name: 'Aluminium Partition',
    detail: 'Modern, functional partitions.\nEfficient workspace solutions.\nSleek and practical.',
    img: img2,
    link: 'https://photos.app.goo.gl/Y95uvzTuN54c8xZx8'
  },
  {
    name: 'Structural Glazing',
    detail: 'Contemporary facade upgrade.\nBoost energy efficiency.\nElegant and modern.',
    img: img3,
    link: 'https://photos.app.goo.gl/nMTsWfoDSxUvBxC89'
  },
  {
    name: 'ACP Sheet Work',
    detail: 'Versatile panel designs.\nRevitalize interiors and exteriors.\nInnovative and flexible.',
    img: img4,
    link: 'https://photos.app.goo.gl/BfGEqQGF6bwqyytK7'
  },
  {
    name: 'Laminate Doors',
    detail: 'Elegant, strong doors.\nRedefine sophistication.\nQuality craftsmanship.',
    img: img5,
    link: 'https://photos.app.goo.gl/rpGhXtbCqom7gmku8'
  },
  {
    name: 'DGU Glass Fitting',
    detail: 'Clear and energy-efficient.\nCreate serene environments.\nModern and sustainable.',
    img: img6,
    link: 'https://photos.app.goo.gl/pZdGANJQcprWZ4Tw6'
  },
  {
    name: '12mm Toughened Glass',
    detail: 'Safe and stylish glass.\nVarious applications.\nDurable and versatile.',
    img: img7,
    link: 'https://photos.app.goo.gl/VmYHUfYUtRVMuotN7'
  }
]
