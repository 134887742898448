import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css'; // Ensure this path is correct for your Swiper version
import './slider.css';
import { SliderProducts } from "../../data/products";

const Slider = () => {
    const swiperRef = useRef(null);

    // Function to get Swiper instance
    const getSwiperInstance = () => {
        return swiperRef.current ? swiperRef.current.swiper : null;
    };

    const handleMouseEnter = () => {
        const swiper = getSwiperInstance();
        if (swiper) {
            swiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        const swiper = getSwiperInstance();
        if (swiper) {
            swiper.autoplay.start();
        }
    };

    return (
        <div className='s-container'>
            <Swiper
                ref={swiperRef}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
                loop={true}
                slidesPerView={3}
                pagination={{ clickable: true }}
                spaceBetween={20}
                slidesPerGroup={1}
                speed={1000}
                autoplay={{
                    delay: 2000, // Adjust delay as needed
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    667: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                }}
                onSwiper={(swiper) => { swiperRef.current = { swiper }; }} // Ensure swiperRef is properly set
            >
                {SliderProducts.map((slide, i) => (
                    <SwiperSlide
                        key={i}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <a href={slide.link} target="_blank" rel="noopener noreferrer">
                            <div className='left-s'>
                                <div className='name'>
                                    <span>{slide.name}</span>
                                </div>
                                <span>{slide.detail}</span>
                                <div>Check more</div>
                            </div>
                            <img src={slide.img} alt='product' className='img-p' />
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Slider;
